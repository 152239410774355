import logo from './logo.svg';
import './App.css';
import React from 'react';
import {useState, useEffect} from 'react';
import { BrowserMultiFormatReader, MultiFormatReader, BarcodeFormat } from '@zxing/library';


function App() {
  const [result, setResult] = useState("no data");
  const [codeReader, setCodeReader] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState("");
  const [devices, setDevices] = useState([]);
  

  const initZxing = () =>{
    const reader = new BrowserMultiFormatReader();
    // console.log(reader.listVideoInputDevices);
    reader.listVideoInputDevices()
      .then((videoInputDevices) => {
        const rs = JSON.parse(JSON.stringify(videoInputDevices));
        setCodeReader(reader);
        setDevices(rs);
        setSelectedDevice(rs[0].deviceId);
      })
      .catch((err) => {
        console.error(err)
      });
    }

  const startDevice = () => {
    // codeReader.decodeFromVideoDevice(selectedDevice, 'video', (result, err) => {
    //   if (result) {
    //     setResult(result);
    //   } 
    //   if (err) {
    //     console.error(err)
    //     setResult(err);        
    //   }
    // })
    codeReader.decodeFromVideoDevice(selectedDevice, 'video', (resultV, err)=>{
      console.log(resultV);
      if (resultV) {
        setResult(resultV)
        console.log(`Started continous decode from camera with id ${selectedDevice}`);
      }
    });
    // if (codeReader === null) {
    //   initZxing();
    // }else{
    //   codeReader.reader.readers.map((list, index)=>{
    //     for (const property in list) {
    //       // console.table(Object.getOwnPropertyNames(list[property]))
    //       console.table(`${property}: ${list[property]}`);
    //     }
    //   })

    //   // console.log(codeReader.reader.readers);
    // }
  }

  useEffect(() => {
    initZxing();
  }, [])
  

  return (
    <>
      <div style={{padding:"30px"}}>
        <div>
          <video id="video" width="100%" height="200" style={{border:"1px solid gray"}}></video>
        </div>

        <div>
          <button className="button" id="startButton" onClick={initZxing} style={{height:'40px', width:'100%', margin:"10px 0"}}>Init</button><br/>
          <button className="button" id="startButton" onClick={startDevice} style={{height:'40px', width:'100%', margin:"10px 0 "}}>Start</button><br/>
          <button className="button" id="resetButton" style={{height:'40px', width:'100%', margin:"10px 0"}}>Reset</button>
        </div>       

        <div id="sourceSelectPanel">
          <label>Change video source:</label>
          <select id="sourceSelect" defaultValue={selectedDevice} style={{maxWidth:'400px'}} onChange={(e)=>setSelectedDevice(e.target.value)}>
            {devices.map((device,index)=>{
              return(<option key={index}
                value={device.deviceId}>
                  {device.label}
                </option>
              )
            })}
          </select>

        </div>

        <label>Result:</label>
        <div id="result">{result}</div>
      </div>
    </>
  );
}

export default App;
